/* ==========================================================================
   Variables
   ========================================================================== */

:root {
  --blue: #004b6b;
  --aqua: #00c3cd;
  --orange: #fa4008;
  --grey-light: #e5e5e5;
  --grey-dark: #666666;
  --white: #ffffff;

  --row: 92em;

  --header-height: 100px;
}
@custom-media --tiny (min-width: 30em);
@custom-media --sm (min-width: 44em);
@custom-media --md (min-width: 58em);
@custom-media --lg (min-width: 72em);
@custom-media --xl (min-width: 86em);

@custom-media --max-md (max-width: 58em);